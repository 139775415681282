import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/Section/Section';
import Headline from '../components/Headline/Headline';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Diagnostik" />
    <Section>
      <Container>
        <Headline subtitle="Praxis für Orthopädie">Impressum</Headline>

        <h2>Angaben gemäß § 5 TMG:</h2>
<p>Praxis für Orthopädie Dr. med. Burkhard Schubert</p>

<h3>Gesetzliche Berufsbezeichnung:</h3>
<p>Facharzt für Orthopädie, erworben in Deutschland</p>

<h3>Postanschrift:</h3>
<p>Dr.-Ruer-Platz 1
<br/>44787 Bochum
<br/></p>

<h3>Kontakt:</h3>
<p>Telefon: 0234/978 428 28
<br/>Telefax: 0234/978 428 29
<br/>E-Mail: praxis@schubert-bochum.de</p>

<h3>Vertreten durch:</h3>
<p>Dr. med. B Schubert<br/></p>

<h3>Aufsichtsbehörde</h3>
<p>Kassenärztliche Vereinigung Westfalen Lippe
<br/>(KVWL), Dortmund
<br/>Körperschaft des öffentlichen Rechts
<br/>Robert-Schimrigk-Straße 4-6
<br/>44141 Dortmund
<br/>Telefon: 0049 231 94320
<br/>www.kvwl.de<br/></p>

<h3>Zuständige Kammer:</h3>
<p>Ärztekammer Westfalen-Lippe
<br/>Körperschaft des öffentlichen Rechts
<br/>Gartenstraße 210-214
<br/>48147 Münster
<br/>Telelfon: 0251/9290
<br/>Fax: 0251/9292999 
<br/>www.kvwl.de
<br/></p>

<h3>Berufsrechtliche Regelungen</h3>
<p><a href="https://www.aekwl.de/fileadmin/rechtsabteilung/doc/Berufsordnung.pdf">Berufsordnung der Ärztekammer Westfalen-Lippe</a></p>
<p><a href="https://www.aekno.de/aerzte/gesetze-verordnungen/heilberufsgesetz-nrw">Heilberufsgesetz des Landes NRW</a></p>


<p></p><h2>Hinweise zur Website</h2>
<p></p><p><em>Das Impressum wurde mit dem <a href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der activeMind AG</a> erstellt.</em></p>


      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
